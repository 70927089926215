  <template>
    <div class="card card-custom gutter-b" id="uploadData">
      <div class="card-header d-inline pt-5">
        <div id="panel-1" class="panel">
          <div class="panel-container show">
            <div class="panel-content">
              <div class="row mb-3 mt-2">
                <div
                  class="col-sm-9 col-md-9 d-flex align-items-center justify-content-start"
                >
                  <p
                    style="font-size: 15px; font-weight: 700; margin: -11px 0px 10px 0px"
                  >
                    Tư liệu của lớp: {{ className }}
                  </p>
                  <hr />
                </div>
                <div
                  class="col-sm-9 col-md-9 d-flex align-items-center justify-content-start"
                >
                  <div class="col-xxl-3 col-md-4 pl-0">
                    <div class="form-group w-100">
                      <el-input
                        class="shadow-sm"
                        type="text"
                        v-model="query.keywords"
                        clearable
                        placeholder="Tìm tư liệu"
                      ></el-input>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-sm-6 d-flex align-items-center pl-0">
                    <label for="">&ensp;</label>
                    <div class="form-group" @click="searchData">
                      <button class="btn btn-primary font-weight-bold mr-2 btn-search">
                        <i class="fa fa-search mr-1"></i>Tìm kiếm
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="col-sm-12 col-md-3 col-sm-3 d-flex dt-buttons"
                >
                  <div>
                    <button
                      class="btn btn-success btn-sm mr-1 upload"
                      tabindex="0"
                      @click="dialogUpload = true"
                      aria-controls="dt-basic-example"
                      type="button"
                    >
                      <span><i class="fa fa-upload mr-1"></i>Tải tệp lên</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="example-preview table-responsive">
                <table
                  class="table table-bordered"
                  v-loading="loadingTable"
                  element-loading-text="Loading..."
                >
                  <thead>
                    <tr style="background-color: #f8f8f8">
                      <th>STT</th>
                      <th>Tên tư liệu</th>
                      <th>Tư liệu</th>
                      <th>Người upload</th>
                      <th>Thời gian upload</th>
                      <th style="width: 150px">Hành động</th>
                    </tr>
                  </thead>
                  <tbody v-if="tableDataImg?.length > 0">
                    <tr v-for="(item, index) in tableDataImg" :key="index">
                      <td style="width: 80px">{{ (pagination.per_page) * (pagination.current_page - 1) + (index + 1) }}</td>
                      <td>{{ item ? item.name : "" }}</td>
                      <td>
                        <img
                          v-if="
                            item.attach_video_image_files[0]?.mime_type.includes(
                              'image'
                            )
                          "
                          @click="carousel(index)"
                          :src="item.media + '#t=0.1'"
                          class="imgUrl cursor-pointer"
                        />
                        <video
                          v-else
                          :src="item.media + '#t=0.1'"
                          class="imgUrl cursor-pointer d-flex align-items-center mt-2"
                          @click="carousel(index)"
                        ></video>
                      </td>
                      <td>
                        {{
                          item.created_user
                            ? item.created_user.id +
                              " - " +
                              item.created_user.name
                            : ""
                        }}
                      </td>
                      <td>
                        {{ item.created_at | formatDateTimeVietnam }}
                      </td>
                      <td>
                        <a
                          title="Tải xuống"
                          href="javascript:"
                          @click="donwloadData(item)"
                          class="btn btn-sm ml-1 btn-icon btn-outline-success"
                        >
                          <i class="fa fa-download"></i>
                        </a>
                        <a
                          title="Xoá"
                          href="javascript:"
                          @click="handleDataDelete(item)"
                          class="btn btn-sm ml-1 btn-icon btn-outline-danger"
                        >
                          <i class="fas fa-trash"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="9" class="event-title no-data">
                        Chưa có dữ liệu
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="position-absolute mt-1" style="right: 40px">
                  Tổng số bản ghi:
                  <b style="color: rgb(54, 153, 255)">{{ pagination.total }}</b>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-4">
                <paginate
                  class="border"
                  v-model="page"
                  :page-count="pagination.last_page"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="clickCallback"
                  :prev-text="'Trước'"
                  :next-text="'Sau'"
                  :container-class="'pagination b-pagination'"
                  :pageLinkClass="'page-link'"
                  :next-link-class="'next-link-item'"
                  :prev-link-class="'prev-link-item'"
                  :prev-class="'page-link'"
                  :next-class="'page-link'"
                  :page-class="'page-item'"
                >
                </paginate>
              </div>
            </div>

            <el-dialog
              id="upload_data_class"
              title="Tải tệp lên"
              :visible.sync="dialogUpload"
            >
              <ValidationObserver ref="form">
                <form>
                  <div class="title">
                    <label for="example"
                      ><b>Tiêu đề</b
                      ><span class="ml-2" style="color: red">*</span></label
                    >
                    <div @click="error.title = false" class="form-group mb-1">
                      <el-input
                        id="example"
                        vid="titleData"
                        data-validator="notEmpty"
                        maxlength="50"
                        placeholder="Nhập tiêu đề"
                        v-model="query.titleData"
                      ></el-input>
                    </div>
                    <span v-if="error.title" class="text-danger mt-2"
                      >Tiêu đề không được để trống *</span
                    >
                  </div>
                  <p class="mt-3">
                    <b>Ảnh/video</b><span class="ml-2" style="color: red">*</span>
                  </p>
                  <uploadDataClass
                    :fileListProp="fileList"
                    :checkDataFile="bigFile"
                    @emitUpdateFile="emitUpdateFile"
                  >
                  </uploadDataClass>
                  <label
                    style="word-break: break-word"
                    class="mt-2"
                    for="exampleTextarea"
                    >Lưu ý: Dung lượng tối đa đối với ảnh là 10MB; đối với video
                    là 300MB. <span class="text-danger">*</span></label
                  >
                </form>
              </ValidationObserver>

              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>

              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogUpload = false">Huỷ</el-button>
                <el-button
                  :disabled="!fileList.length || loadding_upload"
                  v-loading="loadding_upload"
                  style="background-color: #1bc5bd; color: white"
                  @click="confirmUploadData"
                  >Xác nhận</el-button
                >
              </div>
              <div v-if="percentage > 0 && percentage_complete">
                <el-progress :percentage="percentage"></el-progress>
                Upload Progress: {{ percentage }}%
              </div>
            </el-dialog>

            <div class="show-carousel">
              <el-dialog class="m-0 p-0" :visible.sync="dialogCarousel">
                <div class="block">
                  <el-carousel
                    ref="carousel"
                    :autoplay="false"
                    :initial-index="indexImgChoose"
                    trigger="click"
                    height="654px"
                  >
                    <el-carousel-item
                      v-for="(item, index) in tableDataImg"
                      :key="index"
                      :command="item"
                      :setActiveItem="index"
                    >
                      <img
                        v-if="
                          item.attach_video_image_files[0]?.mime_type.includes(
                            'image'
                          )
                        "
                        class="imgCarousell"
                        :src="item.media"
                        :setActiveItem="index"
                        alt=""
                      />
                      <video
                        v-else
                        class="imgCarousell"
                        controls
                        @play="startedPlaying"
                        @pause="pausedPlaying"
                        @ended="endedPlaying"
                      >
                        <source :src="item.media" type="video/mp4" />
                      </video>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </el-dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
  import Paginate from "vuejs-paginate";
  import lodash from "lodash-es";
  import deepdash from "deepdash-es";
  import { mapGetters } from "vuex";
  import uploadDataClass from "../../pages/classes/components/upload-Data-Class.vue";
  import {
    GET_DATA_UPLOAD_CLASS,
    UPLOAD_DATA_CLASS,
    DELETE_DATA_UPLOAD_CLASS,
    CREATE_MULTIPART_UPLOAD,
  } from "@/core/services/store/course/classes.module";
  import Swal from "sweetalert2";
  import { ChangeToSlug } from "@/core/filters";
  import moment from "moment";
  import AWS from "aws-sdk";
  const _ = deepdash(lodash);

  export default {
    name: "upload-data-class",
    components: {
      uploadDataClass,
      Paginate,
    },
    data() {
      return {
        query: {
          keywords: "",
          titleData: "",
        },
        page: 1,
        pagination : {
          last_page: 1,
          total: "",
        },
        fileList: [],
        datePlayed: null,
        ended: false,
        indexImgChoose: "",
        log_request: {
          class_id: "",
          title: "",
        },
        dataDelete: {
          id_data: "",
          media: "",
        },
        error: {
          title: false,
          fileUpload: false,
        },
        dialogUpload: false,
        dialogImageUrl: "",
        dialogVisible: false,
        loadingTable: false,
        dialogCarousel: false,
        dialogDeleteUpload: false,
        tableData: [],
        tableDataImg: [],
        loadding_upload: false,
        className: "",
        bigFile: false,
        percentage: 0,
        percentage_complete: false,
        number_part: 0,
        number_part_big_file: 0
      };
    },
    async mounted() {
      await this.getDataClass();
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Quản lý dạy học", route: "" },
        { title: "Sửa lỗi dạy học" },
      ]);
    },

    computed: {
      ...mapGetters(["currentUser", "currentTitle"]),
    },
    methods: {
      // id tư liệu cần xoá
      handleDataDelete(item) {
        this.dataDelete.id_data = item.id;
        let media = item.media.replace(
          "https://beta-edutalk-cdn.sgp1.digitaloceanspaces.com/ClassDocumentation/",
          ""
        );
        this.dataDelete.media = media;
        Swal.fire({
          title: "Bạn chắc chắn muốn xoá tư liệu?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#7453a6",
          cancelButtonColor: "rgb(79 79 79)",
          confirmButtonText: "Tôi đồng ý",
          cancelButtonText: "Đóng",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loadingTable = true;
            this.$store
              .dispatch(DELETE_DATA_UPLOAD_CLASS, {
                id_class_document: this.dataDelete.id_data,
                media: this.dataDelete.media,
              })
              .then((res) => {
                this.getDataClass();
                this.loadingTable = false;
                this.$notify.success({
                  title: "Thành công",
                  message: "Xoá tư liệu thành công",
                  showClose: false,
                });
              })
              .catch((res) => {
                if (res.status == 500) {
                  this.$notify.error({
                    title: "Thất bại",
                    message: "Không thể xóa tư liệu của người khác tải lên",
                    showClose: false,
                  });
                  this.loadingTable = false;
                } else {
                  this.$notify.error({
                    title: "Thất bại",
                    message: "Xóa tư liệu thất bại",
                    showClose: false,
                  });
                  this.loadingTable = false;
                }
              });
          }
        });
      },

      // lấy tất cả tư liệu của lớp
      getDataClass() {
        this.loadingTable = true;
        let id_class = this.$router.currentRoute.params.id;
        this.$store
          .dispatch(GET_DATA_UPLOAD_CLASS, {
            page: this.page,
            id_class,
            keywords: this.query.keywords,
          })
          .then((res) => {
            this.loadingTable = false;
            this.tableData = res.data.data;
            this.pagination = res.pagination;
            // this.last_page = res.data.last_page;
            // this.totalData = res.pagination.total;
            if (this.tableData.length > 0) {
              this.className = res.data.data[0].class.name;
            }
            this.handleIng(res);
          })
          .catch(() => {});
      },

      handleIng() {
        let dataTableImg = [];
        this.tableData.forEach((values) => {
          values.attach_files.forEach((valueImg) => {
            let data_new = {
              id: values.id,
              attach_files: values.attach_files,
              attach_video_image_files: values.attach_video_image_files,
              class_id: values.class_id,
              created_user: values.created_user,
              created_at: values.created_at,
              media_ids: values.media_ids,
              name: values.name,
              updated_at: values.updated_at,
              media: valueImg,
            };
            dataTableImg.push(data_new);
          });
        });

        this.tableDataImg = dataTableImg;
      },

      pushParamsUrl() {
        this.$router.push({
          name: "upload-data-class",
          query: {
            page: this.page,
          },
        });
      },

      searchData() {
        this.page = 1;
        this.getDataClass();
      },

      carousel(imageIndex) {
        this.dialogCarousel = true;
        this.indexImgChoose = imageIndex;
        setTimeout(() => {
          this.$refs.carousel.setActiveItem(imageIndex);
        }, 0);
      },

      // api tải tư liệu lớp hoc
      donwloadData(item) {
        let afterDot = item.attach_files[0].substring(
          item.attach_files[0].lastIndexOf(".")
        );
        fetch(item.attach_files[0])
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download =
              ChangeToSlug(item.attach_video_image_files[0].file_name) + afterDot;
            link.click();
            this.$notify.success({
              title: "Thành công",
              message: "Tải tư liệu thành công",
              showClose: false,
            });
          })
          .catch((e) => {
            this.$notify.error({
              title: "Thất bại",
              message: "Tải tư liệu thất bại",
              showClose: false,
            });
          });
      },

      remoteURL(url) {
        return url;
      },

      async confirmUploadData() {
        if (!this.query.titleData || !this.fileList.length) {
          this.error.title = true;
        } else {
          this.loadding_upload = true;
          this.log_request.class_id = this.$router.currentRoute.params.id;
          this.log_request.title = this.query.titleData;
          if (!this.bigFile) {
            this.uploadDataSmall();
          } else {
            this.uploadBigData();
          }
        }
      },

      clickCallback(obj) {
        this.getDataClass();
        this.page = obj;
      },

      async uploadDataSmall() {
        this.percentage_complete = true;
        this.percentage = 2;
        let number_part = this.fileList.length;
        let formData = new FormData();
        for (let key in this.log_request) {
          formData.append(key, this.log_request[key]);
        }

        for (let i = 0; i < this.fileList.length; i++) {
          await formData.append("fileList[]", this.fileList[i].raw);
          await this.$store
            .dispatch(UPLOAD_DATA_CLASS, formData)
            .then((res) => {
              this.percentage = Math.round((100 / number_part) * i);
              if (i + 1 === number_part) {
                this.percentage = 0;
                this.percentage_complete = false;
                this.dialogUpload = false;
                this.loadding_upload = false;
                this.$notify.success({
                  title: "Thành công",
                  message: "Upload tư liệu thành công",
                  showClose: false,
                });
                this.getDataClass();
                this.fileList = [];
                this.query.titleData = "";
              }
            })
            .catch(() => {
              this.$notify.error({
                title: "Thất bại",
                message: "Upload tư liệu thất bại",
                showClose: false,
              });
            });
          formData.delete("fileList[]");
        }
      },

      async uploadBigData() {
        AWS.config.update({
          accessKeyId: "DO00NWAFNG3FUBNJ38M8",
          secretAccessKey: "4XdIkd6oSj0XPOsLnrCVplPhwJlK6hYnB8cQ4Ylyv4o",
          endpoint: new AWS.Endpoint("https://sgp1.digitaloceanspaces.com"),
          s3ForcePathStyle: true,
        });
        const s3 = new AWS.S3();
        // Chia nhỏ file thành các phần (parts)
        let file = this.fileList[0];
         let number_part_big_file = this.fileList[0];
         this.number_part_big_file = number_part_big_file.size / 30;
         this.number_part_big_file = Math.floor(this.number_part_big_file / 1000000);
        const name = moment().format("YYYY_MM_DD-HH:mm:ss") + "-" + file.name;
        const Bucket = "beta-edutalk-cdn/ClassDocumentation";
        const params = {
          Bucket: Bucket,
          Key: name,
          ACL: "public-read",
        };

        const initiateResponse = await s3.createMultipartUpload(params).promise();
        const uploadId = initiateResponse.UploadId;
        const partSize = 30 * 1024 * 1024; // chia nhỏ thành nhiều fiel 30 MB
        const numParts = Math.ceil(file.size / partSize);
        let uploadPromises = [];
        for (let i = 0; i < numParts; i++) {
          const start = i * partSize;
          const end = Math.min(start + partSize, file.size);
          const partParams = {
            Bucket: Bucket,
            Key: name,
            PartNumber: i + 1,
            UploadId: uploadId,
            Body: file.raw.slice(start, end),
          };

          uploadPromises.push(
            s3
              .uploadPart(partParams)
              .on("httpUploadProgress", (progress) => {
                this.percentage_complete = true;
                const uploadedBytes = progress.loaded;
                const totalBytes = file.size;
                const currentProgress = (Math.floor((uploadedBytes / totalBytes) * 100 * (this.number_part_big_file)));
                this.percentage = currentProgress;

              })
              .promise()
              .then((res) => {
                this.percentage_complete = false;
              })
              .catch((error) => {})
          );
        }
        await Promise.all(uploadPromises);
        let parts = await s3
          .listParts({
            Bucket: Bucket,
            Key: name,
            UploadId: uploadId,
          })
          .promise();
        const completeParams = {
          Bucket: Bucket,
          Key: name,
          MultipartUpload: {
            Parts: parts.Parts.map((e) => ({
              ETag: e.ETag,
              PartNumber: e.PartNumber,
            })),
          },
          UploadId: uploadId,
        };
        const completeResponse = await s3
          .completeMultipartUpload(completeParams)
          .promise();

        this.$store
          .dispatch(CREATE_MULTIPART_UPLOAD, {
            name,
            data: this.log_request,
            completeResponse,
          })
          .then((res) => {
            this.updateSuccess = true;
            this.dialogUpload = false;
            this.loadding_upload = false;
            this.$notify.success({
              title: "Thành công",
              message: "Upload tư liệu thành công",
              showClose: false,
            });
            this.getDataClass();
            this.query.titleData = "";
            this.fileList = [];
          })
          .catch(() => {
            this.$notify.error({
              title: "Thất bại",
              message: "Upload tư liệu thất bại.",
              showClose: false,
            });
          });
      },

      emitUpdateFile(e, bigFile) {
        this.bigFile = bigFile;
        this.fileList = e;
        if(this.bigFile && this.fileList.length > 0) {
        this.fileList = []
        this.bigFile = false;
      }
      },

      startedPlaying() {
        var self = this;
        self.datePlayed = new Date();
      },

      pausedPlaying() {},

      endedPlaying() {
        var self = this;
        self.ended = true;
      },
    },
  };
  </script>

  <style>
  #uploadData .gift .el-dialog {
    width: 60%;
  }

  @media screen and (max-width: 425.5px) {
    #uploadData .gift .el-dialog {
      width: 80%;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    #uploadData .gift .el-dialog {
      width: 80%;
    }
  }

  #uploadData .btn-upload {
    color: white;
    background-color: #667ca4;
    border-radius: 5px;
  }

  #uoload_data_class .el-dialog__body {
    padding: 8px 20px;
  }

  #uploadData .imgUrl {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  #uploadData .imgCarousell {
    width: 100%;
    height: 656px;
  }

  #uploadData .table-bordered th,
  #uploadData .table-bordered td {
    padding-left: 30px;
  }

  #uploadData .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  #uploadData .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  #uploadData .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  #uploadData .show-carousel .el-dialog__header,
  #uploadData .show-carousel .el-dialog__body {
    padding: 0 !important;
    margin: 0 !important;
  }

  #upload_data_class {
    z-index: 1000 !important;
  }

  #upload_data_class .el-dialog__body {
    border-bottom: 1px solid silver;
    border-top: 1px solid silver;
  }

  .dt-buttons {
    justify-content: end;
  }

  #uploadData tbody tr {
    line-height: 130px;
    height: 130px;
  }

  @media screen and (max-width: 426px) {
    #uploadData .dt-buttons {
      margin: -5px -17px 7px 0px;
      justify-content: start;
    }

    #uploadData tbody tr {
      line-height: 35px;
    }

    #uploadData .block .el-carousel,
    #uploadData .imgCarousell {
      height: 400px;
    }

    #uploadData .el-dialog {
      width: 93%;
    }

    #uploadData .btn-search{
     height: 40px;
    }

    #uploadData .input-search{
      width: 200px;
    }

    #uploadData .dialog-footer {
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (max-width: 376px) {
    #uploadData .btn-search{
     height: 40px;
    }

    #uploadData .input-search{
      width: 150px;
    }
  }

  </style>
